import { useState, useEffect, useContext, useRef } from "react";
import {
  Header,
  Table,
  Button,
  Label,
  Progress,
  Modal,
  Segment,
  Icon,
  Input,
  Dropdown,
  Popup,
  Checkbox,
  List,
} from "semantic-ui-react";
import {
  CUSTOMERS,
  ADD_CUSTOMERS,
  ADD_CUSTOMER,
  THEATERS,
  STUDIOS,
  UPDATE_CUSTOMER,
  CIRCUITS,
  TITLES,
  UPDATE_CUSTOMERS,
  DELETE_CUSTOMER,
  MESSAGES,
  ADD_MESSAGE,
  DELETE_MESSAGE,
  PING_EXHIBITOR,
} from "../graphQueries";
import { useLazyQuery, useMutation } from "@apollo/client";
import PageModal from "../PageModal";
import AddEditCustomer from "./AddEditCustomer";
import { AuthContext } from "../AuthContext";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import moment from "moment-timezone";
import { importFields } from "./importFields";
import Alert from "../Alert/Alert";
import { exportData } from "./exportData";
import { statesFilter } from "../states";
import { statuses, exhibitorStatuses } from "./statusLists";
import Messages from "./Messages";
import Ping from "./Ping";

const Customers = (props) => {
  const { loginContext } = useContext(AuthContext);
  const [customersList, setCustomersList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [preImport, setPreImport] = useState(false);
  const [importTitleId, setImportTitleId] = useState("");
  const [importOpen, setImportOpen] = useState(false);
  const [importing, setImporting] = useState(false);
  const [sort, setSort] = useState({ column: "created", order: "descending" });
  const [importMessage, setImportMessage] = useState("");
  const [importSuccess, setImportSuccess] = useState(false);
  const [theaterList, setTheaterList] = useState([]);
  const [studioList, setStudioList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [circuitsList, setCircuitsList] = useState([]);
  const [titleList, setTitleList] = useState([]);
  const [titleFilter, setTitleFilter] = useState([]);
  const [selectedTitleId, setSelectedTitleId] = useState("All");
  const [circuitFilter, setCircuitFilter] = useState([]);
  const [searching, setSearching] = useState(false);
  const [filteredList, setFiltered] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [fetchArchived, setFetchArchived] = useState(false);
  const [isBulkUpdate, setBulkUpdate] = useState(false);
  const [isBooking, setBooking] = useState(false);
  const [customersToUpdate, setCustomersToUpdate] = useState([]);
  const [filterTerms, setFilterTerms] = useState([
    { id: "status", value: "All" },
    { id: "circuitId", value: "All" },
    { id: "state", value: "All" },
    { id: "groupSize", value: "" },
    { id: "firstName", value: "" },
    { id: "lastName", value: "" },
    { id: "theaterName", value: "" },
    { id: "company", value: "" },
  ]);
  const [searched, setSearched] = useState(false);
  const [newCustomersToUpdate, setNewCTU] = useState([]);
  const [bulkUpdating, setBulkUpdating] = useState(false);
  const [isConfirmApprove, setConfirmApprove] = useState(false);
  const [isConfirmDelete, setConfirmDelete] = useState(false);
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const [recordsDisplayed, setRecordsDisplayed] = useState(0);
  const [allSelected, setAllSelected] = useState(false);
  const [mode, setMode] = useState("");
  const [searchBy, setSearchBy] = useState("name");
  const [searchRadius, setSearchRadius] = useState("8047");
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [titleOptions, setTitleOptions] = useState([]);
  const [statusesFilter, setStatFilter] = useState([]);
  const [exhibitorStatusesFilter, setExhibStatFilter] = useState([]);
  const [warning, setWarning] = useState(false);
  const [messagesList, setMessagesList] = useState([]);
  const [isMessages, setIsMessages] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [isPing, setPing] = useState(false);
  const [pingMessage, setPingMessage] = useState("");
  const [updateObj, setUpdateObj] = useState({});
  const [errorFields, setErrorFields] = useState({
    firstName: false,
    lastName: false,
    company: false,
    industry: false,
    address: false,
    city: false,
    state: false,
    zipCode: false,
    phone: false,
    email: false,
    date: false,
    titleId: false,
    theaterId: false,
    groupSize: false,
    studioId: false,
    status: false,
  });

  const clearTimerRef = useRef();

  const isExhibitor = loginContext.role.includes("Exhibitor");

  const isStudio = loginContext.role.includes("Studio");

  const isAdmin = loginContext.role.includes("Admin");

  const readOnly = loginContext.readOnly;

  const disableExport = loginContext.disableExport;

  const searchByOptions = [
    {
      text: "Name",
      value: "name",
    },
    {
      text: "Zip Code",
      value: "zip",
    },
  ];
  const radiusOptions = [
    {
      text: "1 Mile",
      value: "1609",
    },
    {
      text: "3 Miles",
      value: "4828",
    },
    {
      text: "5 Miles",
      value: "8047",
    },
    {
      text: "10 Miles",
      value: "16093",
    },
    {
      text: "15 Miles",
      value: "24140",
    },
    {
      text: "20 Miles",
      value: "32187",
    },
    {
      text: "25 Miles",
      value: "40234",
    },
  ];

  const priorities = [
    {
      text: "Standard",
      value: "Standard",
    },
    {
      text: "Studio Pay",
      value: "Studio Pay",
    },
    {
      text: "Third Party Pay",
      value: "Third Party Pay",
    },
    {
      text: "VIP",
      value: "VIP",
    },
  ];

  const archiveOptions = [
    {
      text: "Active Campaigns",
      value: "Active Campaigns",
    },
    {
      text: "All Campaigns",
      value: "All Campaigns",
    },
  ];

  //component did mount effects

  useEffect(() => {
    console.log("readonly", loginContext);
    const array = [...statuses];
    array.unshift({
      text: "All Statuses",
      value: "All",
    });
    const exhibArray = [...exhibitorStatuses];
    exhibArray.unshift({
      text: "All Statuses",
      value: "All",
    });
    setStatFilter(array);
    setExhibStatFilter(exhibArray);
  }, []);

  useEffect(() => {
    console.log(selectedCustomer);
  }, [selectedCustomer]);

  useEffect(() => {
    console.log(filterTerms);
  }, [filterTerms]);

  useEffect(() => {
    theaters();
  }, []);

  useEffect(() => {
    console.log(filterTerms);
  }, [filterTerms]);

  const handleRefresh = () => {
    theaters();
    setLoading(true);
  };

  //customers query

  const handleCutomersQuery = () => {
    customers({
      variables: {
        archived: fetchArchived ? true : false,
        titleId: selectedTitleId,
      },
    });
  };
  const [customers] = useLazyQuery(CUSTOMERS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleCustomers_Result(result),
    onError: (error) => handleCustomers_Error(error),
  });
  const handleCustomers_Result = (result) => {
    console.log(result);
    if (result.customers) {
      let newArray = result.customers.map((item) =>
        Object.assign({}, item, {
          titleName: "",
          theaterName: "",
          studioName: "",
          circuitName: "",
        })
      );
      for (const customer of newArray) {
        const abbrv = customer.date
          ? customer.date.slice(0, 19)
          : "Invalid Date";
        Object.assign(customer, { date: abbrv });
        const studioName = getStudio(customer.studioId);
        const circuitName = getCircuit(customer.circuitId);
        const titleName = getTitle(customer.titleId);
        const theater = getTheater(customer.theaterId);
        Object.assign(customer, {
          titleName,
          studioName,
          circuitName,
        });
        if (theater) {
          const theaterName = theater.name;
          const theaterCity = theater.city;
          const theaterState = theater.state;
          const theaterZip = theater.zipCode;
          const theaterRtk = theater.rtkTheaterId;
          Object.assign(customer, {
            theaterName,
            theaterCity,
            theaterState,
            theaterZip,
            theaterRtk,
          });
        }
        // split priority string into array
        const priorities = customer.priority.split(",");
        Object.assign(customer, { priority: priorities });
      }
      const sorted = newArray.sort((a, b) =>
        (b.created || "").localeCompare(a.created || "")
      );
      setCustomersList(sorted);
      setNumPages(Math.ceil(newArray.length / 50));
      setRecordsDisplayed(newArray.length);
      setLoading(false);
      setBulkUpdating(false);
      setCustomersToUpdate([]);
      setBulkUpdate(false);
      setBooking(false);
    }
  };
  const handleCustomers_Error = (error) => {
    console.log(error);
  };

  // theater search query
  const [searchTheaters] = useLazyQuery(THEATERS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleSearchTheaters_Result(result),
    onError: (error) => handleSearchTheaters_Error(error),
  });

  const handleSearchTheaters_Result = (result) => {
    if (result.theaters) {
      setSearchResults(result.theaters);
      setSearching(false);
    }
  };

  const handleSearchTheaters_Error = (error) => {
    console.log(error);
  };

  // theaters query
  const [theaters] = useLazyQuery(THEATERS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleTheaters_Result(result),
    onError: (error) => handleTheaters_Error(error),
  });
  const handleTheaters_Result = (result) => {
    if (result.theaters && result.theaters.length) {
      let newArray = result.theaters.map((item) =>
        Object.assign({}, item, {
          text: `${item.name} | ${item.city}, ${item.state} ${item.zipCode}`,
          value: item.theaterId,
        })
      );

      setTheaterList(newArray);
      studios();
    }
  };
  const handleTheaters_Error = (error) => {
    console.log(error);
  };

  // studios query
  const [studios] = useLazyQuery(STUDIOS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleStudios_Result(result),
    onError: (error) => handleStudios_Error(error),
  });
  const handleStudios_Result = (result) => {
    if (result.studios && result.studios.length) {
      let studioList = [];
      for (const studio of result.studios) {
        const obj = {
          text: studio.name,
          value: studio.studioId,
        };
        studioList.push(obj);
      }
      const sorted = studioList.sort((a, b) =>
        (a.text || "").localeCompare(b.text || "")
      );
      setStudioList(sorted);
      circuits();
    }
  };
  const handleStudios_Error = (error) => {
    console.log(error);
  };

  // circuits query
  const [circuits] = useLazyQuery(CIRCUITS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleCircuits_Result(result),
    onError: (error) => handleCircuits_Error(error),
  });
  const handleCircuits_Result = (result) => {
    if (result.circuits && result.circuits.length) {
      let newArray = result.circuits.map((item) =>
        Object.assign({}, item, { text: item.circuit, value: item.circuitId })
      );
      const sorted = newArray.sort((a, b) =>
        (a.circuit.toUpperCase() || "").localeCompare(
          b.circuit.toUpperCase() || ""
        )
      );

      setCircuitsList(sorted);
      const filterArray = [...sorted];
      const allObj = {
        text: "All Circuits",
        value: "All",
      };
      filterArray.unshift(allObj);
      setCircuitFilter(filterArray);
      titles();
    }
  };
  const handleCircuits_Error = (error) => {
    console.log(error);
  };

  // titles query
  const [titles] = useLazyQuery(TITLES, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleTitles_Result(result),
    onError: (error) => handleTitles_Error(error),
  });
  const handleTitles_Result = (result) => {
    if (result.titles && result.titles.length) {
      let newArray = result.titles.map((item) =>
        Object.assign({}, item, { text: item.name, value: item.titleId })
      );

      setTitleList(newArray);
      const filterArray = [...newArray];
      const sortedFilterArray = filterArray.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      const allObj = {
        text: "All Titles",
        value: "All",
      };
      sortedFilterArray.unshift(allObj);
      setTitleFilter(sortedFilterArray);
      handleCutomersQuery();
      props.handleFetchCustomers(fetchArchived ? true : false, selectedTitleId);
    }
  };
  const handleTitles_Error = (error) => {
    console.log(error);
  };

  // message query

  const [messages] = useLazyQuery(MESSAGES, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleMessages_Result(result),
    onError: (error) => handleMessages_Error(error),
  });

  const handleMessages_Result = (result) => {
    console.log(result);
    setMessagesList(result.messages);
    handleCutomersQuery();
  };

  const handleMessages_Error = (error) => {
    console.log(error);
  };

  //deleteCustomer mutation
  const handleConfirmDelete = () => {
    setConfirmDelete(true);
  };
  const handleDeleteCustomer = () => {
    setConfirmDelete(false);
    const customersSelected = customersList.filter((x) => x.selected);
    for (const customer of customersSelected) {
      deleteCustomer({ variables: { customerId: customer.customerId } });
    }
  };
  const [deleteCustomer] = useMutation(DELETE_CUSTOMER, {
    onCompleted: (result) => handleDeleteCustomer_Result(result),
    onError: (error) => handleDeleteCustomer_Error(error),
  });

  const handleDeleteCustomer_Result = (result) => {
    if (result.deleteCustomer && result.deleteCustomer.status === "succeeded") {
      setSuccess(true);
      setSuccessMessage("Deleted successfully.");
      setSelectedCustomer({});
      setLoading(false);
      handleCutomersQuery();
      props.handleFetchCustomers(fetchArchived ? true : false, selectedTitleId);
      setTimeout(() => {
        setSuccess(false);
        setSuccessMessage("");
        setModalOpen(false);
      }, 2000);
    }
    if (result.deleteCustomer && result.deleteCustomer.status === "failed") {
      console.log(result);
      setFailed(true);
      setSuccessMessage("You are not authorized to delete the selected lead.");
      setTimeout(() => {
        setFailed(false);
        setSuccessMessage("");
      }, 3000);
    }
  };

  const handleDeleteCustomer_Error = (error) => {
    console.log(error);
  };

  // addCustomers mutation
  const [addCustomers] = useMutation(ADD_CUSTOMERS, {
    onCompleted: (result) => handleAddCustomers_Result(result),
    onError: (error) => handleAddCustomers_Error(error),
  });
  const handleAddCustomers_Result = (result) => {
    console.log(result);
    if (result.addCustomers && result.addCustomers.status === "succeeded") {
      setImportSuccess(true);
      setImportMessage("Success!");
      handleCutomersQuery();
      props.handleFetchCustomers(fetchArchived ? true : false, selectedTitleId);
      setTimeout(() => {
        setImporting(false);
        setImportSuccess(false);
      }, 2000);
    }
  };
  const handleAddCustomers_Error = (error) => {
    console.log(error);
  };

  // addCustomer mutation
  const [addCustomer] = useMutation(ADD_CUSTOMER, {
    onCompleted: (result) => handleAddCustomer_Result(result),
    onError: (error) => handleAddCustomer_Error(error),
  });
  const handleAddCustomer_Result = (result) => {
    console.log(result);
    if (result.addCustomer && result.addCustomer.status === "succeeded") {
      setSuccess(true);
      setSuccessMessage("Added successfully.");
      setLoading(false);
      handleCutomersQuery();
      props.handleFetchCustomers(fetchArchived ? true : false, selectedTitleId);
      setTimeout(() => {
        setSuccess(false);
        setSuccessMessage("");
        setModalOpen(false);
      }, 2000);
    }
  };
  const handleAddCustomer_Error = (error) => {
    console.log(error);
  };

  // updateCustomer mutation
  const handleUpdateCustomer = () => {
    setLoading(true);
    setDisabled(true);
    const customer = { ...updateObj };
    Object.assign(customer, { customerId: selectedCustomer.customerId });
    delete customer.selected;
    delete customer.titleName;
    delete customer.studioName;
    delete customer.circuitName;
    delete customer.theaterCity;
    delete customer.theaterState;
    delete customer.theaterZip;
    delete customer.theaterRtk;
    delete customer.theaterName;
    delete customer.__typename;
    if (customer.priority) {
      const priorityString = customer.priority.toString();
      Object.assign(customer, { priority: priorityString });
    }
    if (customer.status === "Booked" && !customer.dollarValue) {
      alert(
        "Box Office value must be updated before the lead can be marked as Booked."
      );
      return;
    }
    updateCustomer({
      variables: customer,
    });
  };
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER, {
    onCompleted: (result) => handleUpdateCustomer_Result(result),
    onError: (error) => handleUpdateCustomer_Error(error),
  });
  const handleUpdateCustomer_Result = (result) => {
    console.log(result);
    if (result.updateCustomer && result.updateCustomer.status === "succeeded") {
      setSuccess(true);
      setSuccessMessage("Updated successfully.");
      setLoading(false);
      handleCutomersQuery();
      props.handleFetchCustomers(fetchArchived ? true : false, selectedTitleId);
      setModalOpen(false);
      // special handling for filtered views

      setTimeout(() => {
        setSuccess(false);
        setSuccessMessage("");
        // setDisabled(false);
      }, 2000);
    }
  };
  const handleUpdateCustomer_Error = (error) => {
    console.log(error);
  };

  const handleBulkUpdate = (type) => {
    const selectedCustomers = customersList.filter((x) => x.selected);
    let updateCustomers = JSON.parse(JSON.stringify(selectedCustomers));
    for (const customer of updateCustomers) {
      // if (type === "Booked" && !customer.dollarValue) {
      //   alert(
      //     "Box Office value must be updated before the lead can be marked as Booked."
      //   );
      //   return;
      // }
      const arrayOfKeys = Object.keys(customer);
      const filteredArray = arrayOfKeys.filter(
        (x) => x !== "customerId" && x !== "firstName" && x !== "lastName"
      );
      for (const a of filteredArray) {
        delete customer[a];
      }
      if (type) Object.assign(customer, { status: type });
      // const priorityString = customer.priority.toString();
      // Object.assign(customer, { priority: priorityString });
      // delete customer.selected;
      // delete customer.titleName;
      // delete customer.studioName;
      // delete customer.circuitName;
      // delete customer.theaterCity;
      // delete customer.theaterState;
      // delete customer.theaterZip;
      // delete customer.theaterRtk;
      // delete customer.theaterName;
      // delete customer.__typename;
      // delete customer.priority;
      // delete customer.firstName;
      // delete customer.lastName;
      // delete customer.company;
      // delete customer.email;
      // delete customer.phone;
      // delete customer.address;
      // delete customer.city;
      console.log(customer);
    }
    setCustomersToUpdate(updateCustomers);
    setBulkUpdate(true);
    if (type === "Booked") {
      setBooking(true);
    }
  };

  const handleConfirmBulkUpdate = () => {
    const array = [...customersToUpdate];
    for (const c of array) {
      const arrayOfKeys = Object.keys(c);
      const filteredArray = arrayOfKeys.filter(
        (x) =>
          x !== "customerId" &&
          x !== "status" &&
          x !== "dollarValue" &&
          x !== "auditorium"
      );
      for (const a of filteredArray) {
        delete c[a];
      }
    }
    updateCustomers({
      variables: { customerInfo: array },
    });
  };

  const handleCancelBulkUpdate = () => {
    setCustomersToUpdate([]);
    setBulkUpdate(false);
    setBooking(false);
  };

  // bulk update customers
  const [updateCustomers] = useMutation(UPDATE_CUSTOMERS, {
    onCompleted: (result) => handleUpdateCustomers_Result(result),
    onError: (error) => handleUpdateCustomers_Error(error),
  });

  const handleUpdateCustomers_Result = (result) => {
    console.log(result);
    if (
      result.updateCustomers &&
      result.updateCustomers.status === "succeeded"
    ) {
      handleCutomersQuery();
      props.handleFetchCustomers(fetchArchived ? true : false, selectedTitleId);
      setConfirmApprove(false);
      handleResetSearch();
      //single customer edit
      setSuccess(true);
      setSuccessMessage("Updated successfully.");
      setLoading(false);
      setModalOpen(false);
      // special handling for filtered views ?

      setTimeout(() => {
        setSuccess(false);
        setSuccessMessage("");
        // setDisabled(false);
      }, 2000);
    }
  };

  const handleUpdateCustomers_Error = (error) => {
    console.log(error);
    setBulkUpdating(false);
    //needs UI error handling here ->
  };

  // addMessage mutation
  const [addMessage] = useMutation(ADD_MESSAGE, {
    onCompleted: (result) => handleAddMessage_Result(result),
    onError: (error) => handleAddMessage_Error(error),
  });

  const handleAddMessage_Result = (result) => {
    console.log(result);
    if (result.addMessage && result.addMessage.status === "succeeded") {
      messages({ variables: { customerId: selectedCustomer.customerId } });
    }
  };

  const handleAddMessage_Error = (error) => {
    console.log(error);
  };

  const handleAddMessage = (message, internal) => {
    if (!message) return;
    setNewMessage("");
    addMessage({
      variables: {
        agentId: loginContext.agentId,
        customerId: selectedCustomer.customerId,
        message,
        internal,
      },
    });
  };

  //delete message mutation
  const [deleteMessage] = useMutation(DELETE_MESSAGE, {
    onCompleted: (result) => handleDeleteMessage_Result(result),
    onError: (error) => handleDeleteMessage_Error(error),
  });

  const handleDeleteMessage_Result = (result) => {
    if (result.deleteMessage.status === "succeeded") {
      messages({ variables: { customerId: selectedCustomer.customerId } });
    }
  };

  const handleDeleteMessage_Error = (error) => {
    console.log(error);
  };

  const handleDeleteMessage = (messageId) => {
    deleteMessage({
      variables: { messageId, agentId: loginContext.agentId, deleted: true },
    });
  };

  // handlers

  const handleChangeNewMessage = (e) => {
    setNewMessage(e.target.value);
  };

  const handleSelectCustomer = (customer) => {
    const array = [...customersList];
    const found = array.filter((x) => x.customerId === customer.customerId);
    Object.assign(found[0], { selected: !found[0].selected });
    setCustomersList(array);
    const numSelected = array.filter((x) => x.selected);
    if (numSelected.length === 1) {
      setSelectedCustomer(numSelected[0]);
    }

    // mark as read for exhibitor
    // if (isExhibitor && customer.status === "Approved") {
    //   console.log("mark it as read!");
    //   Object.assign(customer, { status: "In Progress" });
    //   updateCustomer({ variables: customer });
    // }
  };

  const handleSelectAll = () => {
    let array = [...customersList];
    if (filteredList.length) {
      array = [...filteredList];
    }
    const areAnySelected = array.filter((x) => x.selected);
    if (areAnySelected.length) {
      for (const obj of array) {
        Object.assign(obj, { selected: false });
        setAllSelected(false);
      }
    } else {
      for (const obj of array) {
        Object.assign(obj, { selected: true });
        setAllSelected(true);
      }
    }

    setCustomersList(array);
  };

  const clearError = () => {
    setErrorMessage("");
    setMessagesList([]);
  };

  const handleEditCustomer = () => {
    setModalOpen(true);
    setMode("edit");
    handleChangeTitleOptions(selectedCustomer.studioId);
  };

  const handleSaveNewLead = () => {
    const customerData = { ...selectedCustomer };
    setLoading(true);
    if (
      customerData.firstName &&
      customerData.lastName &&
      customerData.email &&
      customerData.phone &&
      customerData.industry &&
      customerData.company &&
      customerData.address &&
      customerData.city &&
      customerData.state &&
      customerData.zipCode &&
      customerData.groupSize &&
      customerData.titleId &&
      customerData.theaterId &&
      customerData.status
    ) {
      // everything exists, move forward to create
      const theater = getTheater(customerData.theaterId);
      const title = getTitleObj(customerData.titleId);
      console.log(title);
      Object.assign(customerData, {
        agentId: loginContext.agentId,
        circuitId: theater.circuitId,
        studioId: title.studioId,
        priority: customerData.priority.toString(),
        leadSource: loginContext.role,
      });
      addCustomer({ variables: customerData });
    } else {
      let errors = { ...errorFields };
      if (!selectedCustomer.firstName) {
        Object.assign(errors, { firstName: true });
      }
      if (!selectedCustomer.lastName) {
        Object.assign(errors, { lastName: true });
      }
      if (!selectedCustomer.email) {
        Object.assign(errors, { email: true });
      }
      if (!selectedCustomer.phone) {
        Object.assign(errors, { phone: true });
      }
      if (!selectedCustomer.industry) {
        Object.assign(errors, { industry: true });
      }
      if (!selectedCustomer.company) {
        Object.assign(errors, { company: true });
      }
      if (!selectedCustomer.address) {
        Object.assign(errors, { address: true });
      }
      if (!selectedCustomer.city) {
        Object.assign(errors, { city: true });
      }
      if (!selectedCustomer.state) {
        Object.assign(errors, { state: true });
      }
      if (!selectedCustomer.zipCode) {
        Object.assign(errors, { zipCode: true });
      }
      if (!selectedCustomer.groupSize) {
        Object.assign(errors, { groupSize: true });
      }
      if (!selectedCustomer.date) {
        Object.assign(errors, { date: true });
      }
      if (!selectedCustomer.titleId) {
        Object.assign(errors, { titleId: true });
      }
      if (!selectedCustomer.studioId) {
        Object.assign(errors, { studioId: true });
      }
      if (!selectedCustomer.theaterId) {
        Object.assign(errors, { theaterId: true });
      }
      if (!selectedCustomer.status) {
        Object.assign(errors, { status: true });
      }
      setErrorFields(errors);
      setLoading(false);
    }
  };

  const handleImportClose = () => {
    setImportOpen(false);
  };

  const handleImportSubmit = (data) => {
    const dataToImport = [...data.validData];
    console.log(dataToImport);
    const newArray = [];
    for (const customer of dataToImport) {
      const newObj = {};
      const title = titleList.filter((x) => x.titleId === importTitleId);
      const theater = theaterList.filter(
        (x) => x.rtkTheaterId === customer.rtkTheaterId
      );
      const alt = theaterList.filter(
        (x) => x.alternateId === customer.rtkTheaterId
      );
      if (theater.length) {
        Object.assign(newObj, {
          circuitId: theater[0].circuitId,
          theaterId: theater[0].theaterId,
        });
      } else if (alt.length) {
        Object.assign(newObj, {
          circuitId: alt[0].circuitId,
          theaterId: alt[0].theaterId,
        });
      } else {
        Object.assign(newObj, {
          circuitId: "6577b8335ae288796caf23f3",
          theaterId: "6577b87881504313e34cbcc7",
        });
      }
      Object.assign(newObj, {
        agentId: loginContext.agentId,
        titleId: importTitleId,
        studioId: title[0].studioId,
        firstName: customer.firstName,
        lastName: customer.lastName,
        email: customer.email,
        phone: customer.phone,
        groupSize: customer.groupSize,
        auditorium: customer.auditorium,
        dollarValue: customer.dollarValue,
        seats: customer.seats,
        ticketPrice: customer.ticketPrice,
        industry: customer.industry,
        company: customer.company,
        address: customer.address,
        city: customer.city,
        state: customer.state,
        zipCode: customer.zipCode,
        leadSource: customer.leadSource,
      });
      if (isExhibitor) {
        Object.assign(newObj, { status: "Waiting For Studio Approval" });
      }
      const combinedDateTime = `${customer.date} ${customer.time}`;
      const formattedDate = moment(combinedDateTime).format();
      const abbrv = formattedDate.slice(0, 19);
      Object.assign(newObj, { date: abbrv });
      newArray.push(newObj);
    }
    console.log(newArray);
    setImporting(true);
    setImportSuccess(false);
    setImportMessage(`Importing ${data.validData.length} customers...`);
    addCustomers({ variables: { customerInfo: newArray } });
  };

  const handleSort = (column) => {
    const newOrder = sort.order === "ascending" ? "descending" : "ascending";
    let sortList = [...customersList];
    if (filteredList && filteredList.length > 0) {
      sortList = [...filteredList];
    }
    if (column === "firstName" && newOrder === "ascending") {
      sortList.sort((a, b) =>
        (a.firstName || "").localeCompare(b.firstName || "")
      );
    }
    if (column === "firstName" && newOrder === "descending") {
      sortList.sort((a, b) =>
        (b.firstName || "").localeCompare(a.firstName || "")
      );
    }
    if (column === "lastName" && newOrder === "ascending") {
      sortList.sort((a, b) =>
        (a.lastName || "").localeCompare(b.lastName || "")
      );
    }
    if (column === "lastName" && newOrder === "descending") {
      sortList.sort((a, b) =>
        (b.lastName || "").localeCompare(a.lastName || "")
      );
    }
    if (column === "company" && newOrder === "ascending") {
      sortList.sort((a, b) => (a.company || "").localeCompare(b.company || ""));
    }
    if (column === "company" && newOrder === "descending") {
      sortList.sort((a, b) => (b.company || "").localeCompare(a.company || ""));
    }
    if (column === "industry" && newOrder === "ascending") {
      sortList.sort((a, b) =>
        (a.industry || "").localeCompare(b.industry || "")
      );
    }
    if (column === "industry" && newOrder === "descending") {
      sortList.sort((a, b) =>
        (b.industry || "").localeCompare(a.industry || "")
      );
    }
    if (column === "reference" && newOrder === "descending") {
      sortList.sort((a, b) =>
        (b.reference || "").localeCompare(a.reference || "")
      );
    }
    if (column === "auditorium" && newOrder === "ascending") {
      sortList.sort((a, b) =>
        (a.auditorium || "").localeCompare(b.auditorium || "")
      );
    }
    if (column === "auditorium" && newOrder === "descending") {
      sortList.sort((a, b) =>
        (b.auditorium || "").localeCompare(a.auditorium || "")
      );
    }
    if (column === "seats" && newOrder === "ascending") {
      sortList.sort((a, b) => (a.seats || "").localeCompare(b.seats || ""));
    }
    if (column === "seats" && newOrder === "descending") {
      sortList.sort((a, b) => (b.seats || "").localeCompare(a.seats || ""));
    }
    if (column === "ticketPrice" && newOrder === "ascending") {
      sortList.sort((a, b) =>
        (a.ticketPrice || "").localeCompare(b.ticketPrice || "")
      );
    }
    if (column === "ticketPrice" && newOrder === "descending") {
      sortList.sort((a, b) =>
        (b.ticketPrice || "").localeCompare(a.ticketPrice || "")
      );
    }
    if (column === "value" && newOrder === "ascending") {
      sortList.sort((a, b) =>
        (a.dollarValue || "").localeCompare(b.dollarValue || "")
      );
    }
    if (column === "value" && newOrder === "descending") {
      sortList.sort((a, b) =>
        (b.dollarValue || "").localeCompare(a.dollarValue || "")
      );
    }
    if (column === "email" && newOrder === "ascending") {
      sortList.sort((a, b) => (a.email || "").localeCompare(b.email || ""));
    }
    if (column === "email" && newOrder === "descending") {
      sortList.sort((a, b) => (b.email || "").localeCompare(a.email || ""));
    }
    if (column === "status" && newOrder === "ascending") {
      sortList.sort((a, b) => (a.status || "").localeCompare(b.status || ""));
    }
    if (column === "status" && newOrder === "descending") {
      sortList.sort((a, b) => (b.status || "").localeCompare(a.status || ""));
    }
    if (column === "created" && newOrder === "ascending") {
      sortList.sort((a, b) => (a.created || "").localeCompare(b.created || ""));
    }
    if (column === "created" && newOrder === "descending") {
      sortList.sort((a, b) => (b.created || "").localeCompare(a.created || ""));
    }
    if (column === "groupSize" && newOrder === "ascending") {
      sortList.sort((a, b) =>
        (a.groupSize || "").localeCompare(b.groupSize || "")
      );
    }
    if (column === "groupSize" && newOrder === "descending") {
      sortList.sort((a, b) =>
        (b.groupSize || "").localeCompare(a.groupSize || "")
      );
    }
    if (column === "phone" && newOrder === "ascending") {
      sortList.sort((a, b) => (a.phone || "").localeCompare(b.phone || ""));
    }
    if (column === "phone" && newOrder === "descending") {
      sortList.sort((a, b) => (b.phone || "").localeCompare(a.phone || ""));
    }
    if (column === "zipCode" && newOrder === "ascending") {
      sortList.sort((a, b) => (a.zipCode || "").localeCompare(b.zipCode || ""));
    }
    if (column === "zipCode" && newOrder === "descending") {
      sortList.sort((a, b) => (b.zipCode || "").localeCompare(a.zipCode || ""));
    }
    if (column === "date" && newOrder === "ascending") {
      sortList.sort((a, b) => (a.date || "").localeCompare(b.date || ""));
    }
    if (column === "date" && newOrder === "descending") {
      sortList.sort((a, b) => (b.date || "").localeCompare(a.date || ""));
    }
    if (column === "circuit" && newOrder === "ascending") {
      sortList.sort((a, b) =>
        (a.circuitName || "").localeCompare(b.circuitName || "")
      );
    }
    if (column === "circuit" && newOrder === "descending") {
      sortList.sort((a, b) =>
        (b.circuitName || "").localeCompare(a.circuitName || "")
      );
    }
    if (column === "title" && newOrder === "ascending") {
      sortList.sort((a, b) =>
        (a.titleName || "").localeCompare(b.titleName || "")
      );
    }
    if (column === "title" && newOrder === "descending") {
      sortList.sort((a, b) =>
        (b.titleName || "").localeCompare(a.titleName || "")
      );
    }
    if (column === "theater" && newOrder === "ascending") {
      sortList.sort((a, b) =>
        (a.theaterName || "").localeCompare(b.theaterName || "")
      );
    }
    if (column === "theater" && newOrder === "descending") {
      sortList.sort((a, b) =>
        (b.theaterName || "").localeCompare(a.theaterName || "")
      );
    }
    if (column === "studio" && newOrder === "ascending") {
      sortList.sort((a, b) =>
        (a.studioName || "").localeCompare(b.studioName || "")
      );
    }
    if (column === "studio" && newOrder === "descending") {
      sortList.sort((a, b) =>
        (b.studioName || "").localeCompare(a.studioName || "")
      );
    }
    if (column === "priority" && newOrder === "ascending") {
      sortList.sort((a, b) =>
        (a.priority.toString() || "").localeCompare(b.priority.toString() || "")
      );
    }
    if (column === "priority" && newOrder === "descending") {
      sortList.sort((a, b) =>
        (b.priority.toString() || "").localeCompare(a.priority.toString() || "")
      );
    }
    // setCustomersList(sortList);
    if (filteredList && filteredList.length > 0) {
      setFiltered(sortList);
    } else {
      setCustomersList(sortList);
    }
    setSort({ column, order: newOrder });
  };

  const getTheater = (theaterId) => {
    const theater = theaterList.filter((x) => x.theaterId === theaterId);
    if (theater && theater.length) {
      return theater[0];
    }
  };

  const getTheaterName = (theaterId) => {
    const theater = theaterList.filter((x) => x.theaterId === theaterId);
    if (theater && theater.length) {
      // console.log(theater[0]);
      return (
        <Header as="h5">
          {theater[0].name}
          <Header.Subheader>
            {theater[0].city}, {theater[0].state} {theater[0].zipCode}
          </Header.Subheader>
        </Header>
      );
    }
  };

  const getStudio = (studioId) => {
    const studio = studioList.filter((x) => x.value === studioId);
    if (studio && studio.length) {
      return studio[0].text;
    }
  };

  const getCircuit = (circuitId) => {
    const circuit = circuitsList.filter((x) => x.circuitId === circuitId);
    if (circuit && circuit.length) {
      return circuit[0].circuit;
    }
  };

  const getTitle = (titleId) => {
    const title = titleList.filter((x) => x.titleId === titleId);
    if (title && title.length) {
      return title[0].name;
    }
  };

  const getTitleObj = (titleId) => {
    const title = titleList.filter((x) => x.titleId === titleId);
    if (title && title.length) {
      return title[0];
    }
  };

  const handleChangeTitleOptions = (value) => {
    // filter the titleOptions based on studio
    const titleOptions = titleList.filter((x) => x.studioId === value);
    setTitleOptions(titleOptions);
  };

  const handleChangeFieldString = (e, data) => {
    setDisabled(false);
    let key = data.id;
    let value = data.value;
    if (e === "date") {
      key = "date";
      const time = moment(data).format();
      const abbrv = time.slice(0, 19);
      value = abbrv;
      // check if selected date is too close to current date
      const current = moment();
      const duration = moment.duration(moment(data).diff(current));
      const daysAway = duration.asDays();
      if (daysAway < 3 && isExhibitor) {
        console.log("less than 3 days in the future!");
        setSuccessMessage(
          "The selected date is in the near future. Please contact the Studio directly after submitting this event to ensure that it can be approved in time."
        );
        setWarning(true);
        setTimeout(() => {
          setSuccessMessage("");
          setWarning(false);
        }, 8000);
      }
    }

    if (value !== "" && (key === "dollarValue" || key === "ticketPrice")) {
      const re = new RegExp("^[0-9 .]+$");
      if (!re.test(value)) {
        return;
      }
    }

    if (key === "studioId") {
      // filter the titleOptions based on studio
      handleChangeTitleOptions(value);
    }
    const customer = { ...selectedCustomer };
    const update = { ...updateObj };
    Object.assign(customer, { [key]: value });
    Object.assign(update, { [key]: value });
    resetErrors(key);
    setSelectedCustomer(customer);
    setUpdateObj(update);
  };

  const handleResetSearch = () => {
    setSearched(false);
    setFiltered([]);
    setFilterTerms([
      { id: "status", value: "All" },
      { id: "circuitId", value: "All" },
      { id: "state", value: "All" },
      { id: "groupSize", value: "" },
      { id: "firstName", value: "" },
      { id: "lastName", value: "" },
      { id: "theaterName", value: "" },
      { id: "company", value: "" },
    ]);
  };

  const handleFilter = () => {
    setSearched(true);
    setSelectedCustomer({});
    setSearching(true);
    setPage(1);
    let filteredArray = [...customersList];
    if (filteredList && filteredList.length) {
      filteredArray = filteredList;
    }
    for (const obj of filterTerms) {
      filteredArray = filteredArray.filter((x) =>
        x[obj.id]
          .toLowerCase()
          .includes(
            obj.value === "All"
              ? ""
              : obj.value === null
              ? ""
              : obj.value
              ? obj.value.toLowerCase()
              : ""
          )
      );
    }
    if (filteredArray.length === 0) {
    }
    setSearching(false);
    setFiltered(filteredArray);
  };

  const handleChangeFilter = (e, data) => {
    const array = [...filterTerms];
    const objIndex = array.findIndex((obj) => obj.id === data.id);
    if (data.checked) {
      array[objIndex].value = "GROUP AUDITORIUM BUYOUT";
    } else {
      array[objIndex].value = data.value;
    }
    setFilterTerms(array);
    // handleFilter(array);
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const resetErrors = (item) => {
    let obj = { ...errorFields };
    Object.assign(obj, { [item]: false });
    setErrorFields(obj);
  };

  const handleAddNewLead = () => {
    if (loginContext.studioId) {
      setSelectedCustomer({
        priority: ["Standard"],
        studioId: loginContext.studioId,
        status: "New",
      });
      handleChangeTitleOptions(loginContext.studioId);
    } else {
      setSelectedCustomer({ priority: ["Standard"], status: "New" });
    }
    setModalOpen(true);
    setMode("add");
    const selected = [...customersList];
    for (const obj of selected) {
      Object.assign(obj, { selected: false });
    }
    setCustomersList(selected);
  };

  const handleChangePage = (direction) => {
    setSelectedCustomer({});
    if (direction === "up") {
      setPage(page + 1);
    }
    if (direction === "down") {
      setPage(page - 1);
    }
    const scrollingContainer = document.getElementById("scrollingContainer");
    if (scrollingContainer) {
      scrollingContainer.scrollTo({
        top: 0,
        left: 0,
      });
    }
  };

  const paginate = (array, page_size, page_number) => {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  };

  const handleSelectPage = (e, data) => {
    setPage(data.value);
  };

  const handleFetchArchive = (e, data) => {
    if (data.value === "Active Campaigns") {
      setFetchArchived(false);
      handleRefresh();
    }
    if (data.value === "All Campaigns") {
      setFetchArchived(true);
      handleRefresh();
    }
  };

  const getPingDisabled = () => {
    const selected = customersList.filter((x) => x.selected);
    if (selected.length === 1 && selectedCustomer.status === "Approved") {
      // const daysInt = moment().diff(selectedCustomer.approvalTime, "days");
      const daysInt = calculateBusinessDays(selectedCustomer.approvalTime);
      const timeSinceLastPing = moment().diff(
        selectedCustomer.lastPing,
        "days"
      );
      let longEnough = true;
      if (
        timeSinceLastPing === 0 ||
        timeSinceLastPing === 1 ||
        timeSinceLastPing === 2
      )
        longEnough = false;
      console.log("timeSinceLastPing", timeSinceLastPing);
      console.log("daysInt", daysInt);
      if (isAdmin) return false;
      if (daysInt > 2 && longEnough) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const getEditDisabled = () => {
    const selected = customersList.filter((x) => x.selected);
    if (selected.length === 1) {
      return false;
    } else {
      return true;
    }
  };

  const getUpdateDisabled = () => {
    const selected = customersList.filter((x) => x.selected);
    if (selected.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const getNumSelected = () => {
    const selected = customersList.filter((x) => x.selected);
    return selected.length;
  };

  const handleExport = (ext) => {
    let expData = [];
    expData = filteredList.length ? filteredList : customersList;
    const selected = customersList.filter((x) => x.selected);
    if (selected.length) {
      expData = selected;
    }
    // // prepare export array
    let newArray = [];
    for (const obj of expData) {
      const eventDate = moment(obj.date).format("YYYY-MM-DD LT");
      const dateTime = eventDate.split(" ");
      const newObj = {
        Created: obj.created,
        Circuit: obj.circuitName,
        Theater: obj.theaterName,
        "Theater City": obj.theaterCity,
        "Theater State": obj.theaterState,
        "Theater Zip": obj.theaterZip,
        "Theater Rentrak ID": obj.theaterRtk,
        "First Name": obj.firstName,
        "Last Name": obj.lastName,
        "Group Name": obj.company,
        Industry: obj.industry,
        Reference: obj.reference,
        Email: obj.email,
        Phone: obj.phone,
        Address: obj.address,
        City: obj.city,
        State: obj.state,
        "Zip Code": obj.zipCode,
        // "Requested Event Date": obj.date,
        "Requested Event Date": dateTime[0],
        "Requested Event Time": `${dateTime[1]} ${dateTime[2]}`,
        "Requested Group Size": obj.groupSize,
        "Confirmed Auditorium": obj.auditorium,
        "Confirmed Seats": obj.seats,
        "Price Per Ticket": obj.ticketPrice,
        "Box Office Gross": obj.dollarValue,
        Status:
          obj.status === "Approved" && isExhibitor
            ? "Lead Approved By Studio"
            : obj.status === "Approved" && !isExhibitor
            ? "Sent to Exhibitor"
            : obj.status,
        Studio: obj.studioName,
        Title: obj.titleName,
        "Lead Source": obj.leadSource,
      };
      if (isAdmin || isStudio) {
        Object.assign(newObj, {
          "Has Active Exhibitor": obj.missingOwner ? "No" : "Yes",
        });
      }
      newArray.push(newObj);
    }
    exportData(
      newArray,
      `Leads-export-${moment().format("YYYY-MM-DD-HH-MM-SS")}`,
      ext
    );
  };

  const getPriority = (priorities) => {
    if (Array.isArray(priorities)) {
      const newArray = priorities.map((item) =>
        item === "VIP" ? (
          <Label color="yellow" icon="star" content="VIP" />
        ) : item === "Standard" ? (
          " Standard "
        ) : item === "Third Party Pay" ? (
          <Label color="blue" icon="dollar" content="Third Party Pay" />
        ) : item === "Studio Pay" ? (
          <Label color="green" icon="dollar" content="Studio Pay" />
        ) : (
          item
        )
      );
      const string = newArray;
      return string;
    } else {
      return priorities;
    }
  };

  const getApprovalTimeDisplay = (approvalTime) => {
    const start = moment(approvalTime);
    const end = moment();
    const duration = moment.duration(end.diff(start));
    console.log("duration", duration);
    if (duration._data.days > 0) {
      if (duration._data.days === 1) {
        return `${duration._data.days} day ago`;
      }
      if (duration._data.days > 1) {
        return `${duration._data.days} days ago`;
      }
    } else {
      return "";
    }
  };

  const calculateBusinessDays = (approvalTime) => {
    // EDIT : use of startOf
    let day1 = moment().endOf("day");
    let day2 = moment(approvalTime).startOf("day");
    // EDIT : start at 1
    let adjust = 1;

    if (day1.dayOfYear() === day2.dayOfYear() && day1.year() === day2.year()) {
      return 0;
    }

    if (day2.isBefore(day1)) {
      const temp = day1;
      day1 = day2;
      day2 = temp;
    }

    //Check if first date starts on weekends
    if (day1.day() === 6) {
      //Saturday
      //Move date to next week monday
      day1.day(8);
    } else if (day1.day() === 0) {
      //Sunday
      //Move date to current week monday
      day1.day(1);
    }

    //Check if second date starts on weekends
    if (day2.day() === 6) {
      //Saturday
      //Move date to current week friday
      day2.day(5);
    } else if (day2.day() === 0) {
      //Sunday
      //Move date to previous week friday
      day2.day(-2);
    }

    const day1Week = day1.week();
    let day2Week = day2.week();

    //Check if two dates are in different week of the year
    if (day1Week !== day2Week) {
      //Check if second date's year is different from first date's year
      if (day2Week < day1Week) {
        day2Week += day1Week;
      }
      //Calculate adjust value to be substracted from difference between two dates
      // EDIT: add rather than assign (+= rather than =)
      adjust += -2 * (day2Week - day1Week);
    }
    const result = day2.diff(day1, "days") + adjust;
    if (result > 0) {
      return result;
    }
    // return day2.diff(day1, "days") + adjust;
  };

  const handleViewMessages = () => {
    setIsMessages(true);
    messages({ variables: { customerId: selectedCustomer.customerId } });
  };

  const getOverdueStatus = (item) => {
    const days = calculateBusinessDays(item.approvalTime);
    const overdue = days > 2 && days < 6;
    if (overdue && item.status === "Approved") {
      // this lead was marked as approved 5+ days ago and status has not yet changed.
      return true;
    }
  };

  const getRidiculouslyOverdueStatus = (item) => {
    const days = calculateBusinessDays(item.approvalTime);
    const overdue = days > 5;
    if (overdue && item.status === "Approved") {
      // this lead was marked as approved 5+ days ago and status has not yet changed.
      return true;
    }
  };

  const getPings = (pingCount, lastPing) => {
    const pings = parseInt(pingCount) > 1 ? "pings" : "ping";
    if (parseInt(pingCount) > 0) {
      return (
        <Header>
          {pingCount} {pings} sent to Exhibitor
          {lastPing && (
            <Header.Subheader>
              Last ping sent {moment(lastPing).fromNow()}
            </Header.Subheader>
          )}
        </Header>
      );
    }
  };

  const getStatusTableCell = (status, approvalTime, pingCount, lastPing) => {
    if (status === "New") {
      return (
        <Label
          color={isExhibitor ? "teal" : "green"}
          content={isExhibitor ? "Waiting For Studio Approval" : "New"}
        />
      );
    }
    if (status === "Approved") {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            // justifyContent: "space-between",
          }}
        >
          <Label
            style={{ marginRight: "5px" }}
            color={isExhibitor ? "green" : "grey"}
            content={
              isExhibitor ? "Lead Approved By Studio" : "Sent to Exhibitor"
            }
          />
          {calculateBusinessDays(approvalTime) > 0
            ? `${calculateBusinessDays(approvalTime)} business days ago`
            : null}{" "}
          {pingCount > 0 ? (
            <Popup
              on="hover"
              content={getPings(pingCount, lastPing)}
              trigger={
                <div
                  style={{
                    marginLeft: "5px",
                    borderRadius: "50px",
                    backgroundColor: "#F1F1F1",
                    padding: "5px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Icon name="send" />
                  {pingCount}
                </div>
              }
            />
          ) : null}
        </div>
      );
    }
    if (status === "Contacted") {
      return <Label color="blue" content={status} />;
    }
    if (status === "Booked") {
      return <Label color="purple" content={status} />;
    }
    if (status === "Cold") {
      return <Label color="black" content={status} />;
    }
    if (status === "In Progress") {
      return <Label color="blue" content={status} />;
    }
    if (status === "Pending Studio Approval") {
      return <Label color="blue" content={status} />;
    }
    if (status === "Cancelled") {
      return <Label color="red" content={status} />;
    }
    if (status === "Duplicate") {
      return <Label color="orange" content={status} />;
    }
    if (status === "Completed") {
      return (
        <Label
          style={{ backgroundColor: "#DEB93B", color: "#FFFFFF" }}
          content={isExhibitor ? "Event Approved By Studio" : status}
        />
      );
    }
    if (status === "Flagged") {
      return <Label color="orange" content={status} />;
    }
    if (status === "Insufficient Contact Details") {
      return <Label color="orange" content={status} />;
    }

    if (status === "Request For Quote") {
      return <Label color="blue" content={status} />;
    }
    if (status === "Quote Received") {
      return <Label color="teal" content={status} />;
    }
    if (status === "Quote Approved, Ready For Payment") {
      return <Label color="teal" content={status} />;
    }
  };

  const CustomerList = () => {
    let customers = customersList;

    if (searched) {
      customers = filteredList;
    }
    setNumPages(Math.ceil(customers.length / 50));
    setRecordsDisplayed(customers.length);
    const paged = paginate(customers, 50, page);
    // console.log("num pages", numPages);

    return paged.map((item, index) => {
      const priorities = item.priority;
      for (let p of priorities) {
        if (p === "Standard") {
          p = "Loo";
        }
      }
      const string = priorities.toString();
      const priority = string.replace(",", ", ");
      return (
        <Popup
          key={index}
          disabled={!item.lastUpdateAgentName}
          on="hover"
          content={
            <div>
              Last Edited By:
              <Header
                as="h4"
                content={item.lastUpdateAgentName}
                subheader={moment(item.lastUpdateTimestamp).format("LLL")}
              />
            </div>
          }
          trigger={
            <Table.Row
              error={getRidiculouslyOverdueStatus(item)}
              warning={getOverdueStatus(item)}
              active={item.selected}
              key={index}
              onClick={() => handleSelectCustomer(item)}
              onDoubleClick={() => console.log("double click")}
            >
              <Table.Cell>
                <Checkbox checked={item.selected} />
              </Table.Cell>
              <Table.Cell>{moment(item.created).format("LLL")}</Table.Cell>
              <Table.Cell>
                {getStatusTableCell(
                  item.status,
                  item.approvalTime,
                  item.pingCount,
                  item.lastPing
                )}
              </Table.Cell>
              <Table.Cell>{getPriority(priorities)}</Table.Cell>
              <Table.Cell>
                {item.hasNotes ? (
                  <Icon name="conversation" color="blue" />
                ) : null}
              </Table.Cell>
              <Table.Cell>{item.titleName}</Table.Cell>
              {(isAdmin || isStudio) && (
                <Table.Cell>{item.circuitName}</Table.Cell>
              )}
              <Table.Cell>{getTheaterName(item.theaterId)}</Table.Cell>
              <Table.Cell>{item.market}</Table.Cell>
              {/* <Table.Cell>{item.email}</Table.Cell> */}
              <Table.Cell>{moment(item.date).format("LLL")}</Table.Cell>
              <Table.Cell>{item.firstName}</Table.Cell>
              <Table.Cell>{item.lastName}</Table.Cell>
              <Table.Cell>{item.company}</Table.Cell>
              <Table.Cell>{item.industry}</Table.Cell>
              <Table.Cell>{item.reference}</Table.Cell>
              <Table.Cell>{item.groupSize}</Table.Cell>
              <Table.Cell>{item.auditorium}</Table.Cell>
              <Table.Cell>{item.seats}</Table.Cell>
              <Table.Cell>{formatter.format(item.ticketPrice)}</Table.Cell>

              {(isAdmin || isExhibitor) && (
                <Table.Cell>{item.studioName}</Table.Cell>
              )}
              <Table.Cell>{formatter.format(item.dollarValue)}</Table.Cell>

              {(isAdmin || isStudio) && (
                <Table.Cell>
                  {item.missingOwner ? (
                    <Label color="red" content="No" />
                  ) : (
                    <Label color="green" content="Yes" />
                  )}
                </Table.Cell>
              )}
            </Table.Row>
          }
        />
      );
    });
  };

  const getPages = () => {
    let arrayOfPages = [];
    let count = 1;
    while (count <= numPages) {
      const obj = {
        text: count,
        value: count,
      };
      arrayOfPages.push(obj);
      count++;
    }
    // console.log(arrayOfPages);
    return arrayOfPages;
  };

  const handleTheaterSearch = () => {
    setSearching(true);
    // run titles query for limited list of theaters

    if (searchBy === "zip") {
      searchTheaters({
        variables: {
          zip: searchValue,
          radius: searchRadius,
          circuitId: isExhibitor ? loginContext.circuitId : null,
        },
      });
    }
    if (searchBy === "name") {
      searchTheaters({
        variables: {
          name: searchValue,
          circuitId: isExhibitor ? loginContext.circuitId : null,
        },
      });
    }
  };

  const handleSelectTheater = (theaterObj) => {
    setDisabled(false);
    console.log(theaterObj);
    // find circuit ID
    const circuit = circuitsList.filter(
      (x) => x.circuitId === theaterObj.circuitId
    );
    const obj = { ...selectedCustomer };
    Object.assign(obj, {
      theaterId: theaterObj.theaterId,
      circuitId: circuit[0].circuitId,
    });
    setSelectedCustomer(obj);
    const update = { ...updateObj };
    Object.assign(update, {
      theaterId: theaterObj.theaterId,
      circuitId: circuit[0].circuitId,
    });
    setUpdateObj(update);
    setSearchResults([]);
  };

  const handleTheaterSearchValue = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    setErrorMessage("");
    resetErrors("theater");
    setSearchResults([]);
  };

  const handleChangeRadius = (e, data) => {
    console.log(data.value);
    setSearchRadius(data.value);
    setSearchResults([]);
  };

  const handleChangeSearchBy = (e, data) => {
    console.log(data.value);
    setSearchBy(data.value);
    setSearchResults([]);
    setSearchValue("");
  };

  const handleClearTheaterSelection = () => {
    setSearchResults([]);
    setSearchValue("");
    const obj = { ...selectedCustomer };
    Object.assign(obj, { theaterId: null, circuitId: null });
    setSelectedCustomer(obj);
  };

  const handleTitleFilter = (titleId) => {
    console.log(titleId);
    setSelectedTitleId(titleId);
    handleRefresh();
    setSelectedCustomer({});
  };

  const handleSetPingMessage = (e) => {
    setPingMessage(e.target.value);
  };

  const handlePingExhibitor = () => {
    setLoading(true);
    pingExhibitor({
      variables: { customerId: selectedCustomer.customerId, pingMessage },
    });
  };

  const [pingExhibitor] = useMutation(PING_EXHIBITOR, {
    onCompleted: (result) => handlePingExhibitor_Result(result),
    onError: (error) => handlePingExhibitor_Error(error),
  });

  const handlePingExhibitor_Result = (result) => {
    console.log(result);
    if (result.pingExhibitor.status === "succeeded") {
      setSuccess(true);
      setSuccessMessage("Email sent successfully.");
      setLoading(false);
      handleCutomersQuery();
      setTimeout(() => {
        setSuccess(false);
        setSuccessMessage("");
        setPing(false);
      }, 3000);
    }
    if (result.pingExhibitor.status === "failed") {
      setSuccessMessage(result.pingExhibitor.message);
      setFailed(true);
      setLoading(false);
      setTimeout(() => {
        setSuccessMessage("");
        setFailed(false);
        setPing(false);
      }, 3000);
    }
  };

  const handlePingExhibitor_Error = (error) => {
    console.log(error);
  };

  const handleChangeItemBoxOffice = (e, item) => {
    const value = e.target.value;
    const array = [...customersToUpdate];
    const customer = array.filter((x) => x.customerId === item.customerId);
    Object.assign(customer[0], { dollarValue: value });
  };

  const handleChangeItemAuditorium = (e, item) => {
    const array = [...customersToUpdate];
    const customer = array.filter((x) => x.customerId === item.customerId);
    Object.assign(customer[0], { auditorium: e.target.value });
  };

  const SelectedCustomers = () => {
    return customersToUpdate.map((item, index) => {
      return (
        <Table.Row key={index}>
          <Table.Cell>
            {item.firstName} {item.lastName}
          </Table.Cell>
          <Table.Cell>
            <Icon name="right arrow" />
          </Table.Cell>
          <Table.Cell>
            {getStatusTableCell(
              item.status,
              item.approvalTime,
              item.pingCount,
              item.lastPing
            )}
          </Table.Cell>
          {isBooking && (
            <Table.Cell>
              <Input
                label="$"
                error={!item.dollarValue && item.status === "Booked"}
                placeholder="Enter Box Office in USD"
                value={item.dollarValue}
                onChange={(e) => handleChangeItemBoxOffice(e, item)}
              />
            </Table.Cell>
          )}
          {isBooking && (
            <Table.Cell>
              <Input
                placeholder="Auditorium"
                value={item.auditorium}
                onChange={(e) => handleChangeItemAuditorium(e, item)}
              />
            </Table.Cell>
          )}
        </Table.Row>
      );
    });
  };

  const SearchResults = () => {
    return searchResults.map((item, index) => {
      return (
        <Dropdown.Item key={index} onClick={() => handleSelectTheater(item)}>
          <Header>
            {item.name}
            <Header.Subheader>
              {item.circuit} - {item.city}, {item.state} {item.zipCode}
            </Header.Subheader>
          </Header>
        </Dropdown.Item>
      );
    });
  };

  const selectedCustomers = customersList.filter((x) => x.selected);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 50px)",
        width: "100vw",
        position: "absolute",
        top: "50px",
      }}
    >
      {success && <Alert successMessage={successMessage} />}
      {failed && <Alert failed={true} successMessage={successMessage} />}
      {warning && <Alert warning={true} successMessage={successMessage} />}
      <div
        style={{
          // // maxWidth: "calc(100% - 20px)",
          // // maxHeight: "calc(100% - 70px)",
          // position: "absolute",
          // top: "60px",
          maxWidth: "95%",
          maxHeight: "90%",
          width: "100%",
          backgroundColor: "#F1F1F1",
          borderRadius: "10px",
          padding: "20px",
          zIndex: "1",
          boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.25)",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {importOpen && (
          <ReactSpreadsheetImport
            isOpen={importOpen}
            onClose={handleImportClose}
            onSubmit={handleImportSubmit}
            fields={importFields}
          />
        )}
        {preImport && (
          <PageModal
            open={preImport}
            setOpen={setPreImport}
            clearError={clearError}
            heading="Import Leads"
            size="tiny"
            actions={
              <Button
                disabled={!importTitleId}
                primary
                content="Continue"
                onClick={() => {
                  setPreImport(false);
                  setImportOpen(true);
                }}
              />
            }
          >
            <div>
              <Header>Select Title for Import</Header>
              <Dropdown
                fluid
                value={importTitleId}
                placeholder="Select Title"
                search
                selection
                options={titleList}
                onChange={(e, data) => setImportTitleId(data.value)}
              />
              <Header>
                Required Fields
                <Header.Subheader>
                  Ensure that your spreadsheet includes the following:
                </Header.Subheader>
              </Header>
              <List bulleted>
                <List.Item>First Name</List.Item>
                <List.Item>Last Name</List.Item>
                <List.Item>Email</List.Item>
                <List.Item>Phone</List.Item>
                <List.Item>Group Name</List.Item>
                <List.Item>Industry</List.Item>
                <List.Item>Street Address</List.Item>
                <List.Item>City</List.Item>
                <List.Item>State</List.Item>
                <List.Item>Zip Code</List.Item>
                <List.Item>Group Size</List.Item>
                <List.Item>Event Date</List.Item>
                <List.Item>Event Time</List.Item>
                <List.Item>Rentrak ID</List.Item>
              </List>
              <Header>
                Optional Fields
                <Header.Subheader>
                  Additionally, your spreadsheet may also include the following:
                </Header.Subheader>
              </Header>
              <List bulleted>
                <List.Item>Lead Source</List.Item>
                <List.Item>Auditorium</List.Item>
                <List.Item>Seats</List.Item>
                <List.Item>Ticket Price</List.Item>
                <List.Item>Box Office</List.Item>
              </List>
            </div>
          </PageModal>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            style={{ opacity: 0, pointerEvents: "none" }}
            title="Refresh Leads List"
            loading={loading}
            disabled={loading}
            icon="refresh"
            // style={{ position: "absolute", right: "5px", top: "5px" }}
            onClick={handleRefresh}
          />
          <Header as="h4" style={{ margin: "0px" }}>
            Showing Leads for:{" "}
            <Dropdown
              placeholder="Select Title"
              selection
              search
              options={titleFilter}
              // value={filterTerms[2].value || ""}
              value={selectedTitleId}
              // disabled={searched}
              id="titleId"
              label="Title"
              onChange={(e, data) => handleTitleFilter(data.value)}
            />
          </Header>
          <Header as="h4" style={{ margin: "0px" }}>
            Showing Leads for:{" "}
            <Dropdown
              placeholder="Select Campaigns"
              defaultValue="Active Campaigns"
              selection
              search
              options={archiveOptions}
              disabled={searched}
              onChange={handleFetchArchive}
            />
          </Header>
          <Button
            title="Refresh Leads List"
            loading={loading}
            disabled={loading}
            icon="refresh"
            // style={{ position: "absolute", right: "5px", top: "5px" }}
            onClick={handleRefresh}
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "5px",
            }}
          >
            <label style={{ fontWeight: 600 }}>First Name:</label>
            <Input
              autoComplete="filterField"
              placeholder="First Name"
              value={filterTerms[4].value || ""}
              disabled={searched}
              id="firstName"
              onChange={handleChangeFilter}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "5px",
            }}
          >
            <label style={{ fontWeight: 600 }}>Last Name:</label>
            <Input
              autoComplete="filterField"
              placeholder="Last Name"
              value={filterTerms[5].value || ""}
              disabled={searched}
              id="lastName"
              onChange={handleChangeFilter}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "5px",
            }}
          >
            <label style={{ fontWeight: 600 }}>Group Name:</label>
            <Input
              autoComplete="filterField"
              placeholder="Group Name"
              value={filterTerms[7].value || ""}
              disabled={searched}
              id="company"
              onChange={handleChangeFilter}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "5px",
            }}
          >
            <label style={{ fontWeight: 600 }}>Theater Name:</label>
            <Input
              autoComplete="filterField"
              placeholder="Theater Name"
              value={filterTerms[6].value || ""}
              disabled={searched}
              id="theaterName"
              onChange={handleChangeFilter}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "5px",
            }}
          >
            <label style={{ fontWeight: 600 }}>Status:</label>
            <Dropdown
              placeholder="Select Status"
              search
              selection
              options={isExhibitor ? exhibitorStatusesFilter : statusesFilter}
              value={filterTerms[0].value || ""}
              disabled={searched}
              id="status"
              label="Status"
              onChange={handleChangeFilter}
            />
          </div>
          {!isExhibitor && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "5px",
              }}
            >
              <label style={{ fontWeight: 600 }}>Circuit:</label>
              <Dropdown
                lazyLoad
                placeholder="Select Circuit"
                search
                selection
                options={circuitFilter}
                value={filterTerms[1].value || ""}
                disabled={searched}
                id="circuitId"
                label="Circuit"
                onChange={handleChangeFilter}
              />
            </div>
          )}
          {/* <div
            style={{ display: "flex", flexDirection: "column", padding: "5px" }}
          >
            <label style={{ fontWeight: 600 }}>Title:</label>
            <Dropdown
              placeholder="Select Title"
              selection
              search
              options={titleFilter}
              value={filterTerms[2].value || ""}
              disabled={searched}
              id="titleId"
              label="Title"
              onChange={handleChangeFilter}
            />
          </div> */}
          <div
            style={{ display: "flex", flexDirection: "column", padding: "5px" }}
          >
            <label style={{ fontWeight: 600 }}>State:</label>
            <Dropdown
              placeholder="Select State"
              selection
              search
              options={statesFilter}
              value={filterTerms[2].value || ""}
              disabled={searched}
              id="state"
              label="State"
              onChange={handleChangeFilter}
            />
          </div>

          <Checkbox
            checked={filterTerms[3].value === "GROUP AUDITORIUM BUYOUT"}
            disabled={searched}
            style={{ marginRight: "10px", marginTop: "20px" }}
            id="groupSize"
            label="Only Buyouts"
            onChange={handleChangeFilter}
          />
          <Button
            style={{ marginTop: "20px" }}
            secondary={!searched}
            disabled={searched}
            content="Search"
            onClick={handleFilter}
          />
          <Button
            style={{ marginTop: "20px" }}
            secondary={searched}
            disabled={!searched}
            content="Reset"
            onClick={handleResetSearch}
          />
        </div>
        <div id="scrollingContainer" style={{ overflowY: "auto" }}>
          {loading && (
            <Segment
              placeholder
              style={{ alignItems: "center", backgroundColor: "#F1F1F1" }}
            >
              <Header content="Loading Leads..." />
              <Icon size="big" loading name="circle notch" />
            </Segment>
          )}
          {!loading && customersList.length > 0 && (
            <Table
              sortable
              selectable
              style={{
                borderTop: "0px",
                whiteSpace: "nowrap",
              }}
            >
              <Table.Header
                style={{
                  border: "0px",
                  backgroundColor: "#F1F1F1",
                }}
              >
                <Table.Row>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      zIndex: 1,
                    }}
                  >
                    <Checkbox
                      label="Select All"
                      onChange={handleSelectAll}
                      checked={allSelected}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "created" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "created" ? sort.order : null}
                    onClick={() => handleSort("created")}
                  >
                    Created
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "status" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "status" ? sort.order : null}
                    onClick={() => handleSort("status")}
                  >
                    Status
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "priority" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "priority" ? sort.order : null}
                    onClick={() => handleSort("priority")}
                  >
                    Priority
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "notes" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "notes" ? sort.order : null}
                    onClick={() => handleSort("notes")}
                  >
                    Notes
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "title" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "title" ? sort.order : null}
                    onClick={() => handleSort("title")}
                  >
                    Title
                  </Table.HeaderCell>
                  {(isAdmin || isStudio) && (
                    <Table.HeaderCell
                      style={{
                        position: "sticky",
                        top: 0,
                        borderTop: "1px solid rgba(34,36,38,.15)",
                        background:
                          sort.column === "circuit" ? "aliceblue" : "",
                      }}
                      sorted={sort.column === "circuit" ? sort.order : null}
                      onClick={() => handleSort("circuit")}
                    >
                      Circuit
                    </Table.HeaderCell>
                  )}
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "theater" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "theater" ? sort.order : null}
                    onClick={() => handleSort("theater")}
                  >
                    Theater
                  </Table.HeaderCell>

                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "market" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "market" ? sort.order : null}
                    onClick={() => handleSort("market")}
                  >
                    Market
                  </Table.HeaderCell>

                  {/* <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "email" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "email" ? sort.order : null}
                    onClick={() => handleSort("email")}
                  >
                    Email
                  </Table.HeaderCell> */}
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "date" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "date" ? sort.order : null}
                    onClick={() => handleSort("date")}
                  >
                    Event Date / Time
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background:
                        sort.column === "firstName" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "firstName" ? sort.order : null}
                    onClick={() => handleSort("firstName")}
                  >
                    First Name
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "lastName" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "lastName" ? sort.order : null}
                    onClick={() => handleSort("lastName")}
                  >
                    Last Name
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "company" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "company" ? sort.order : null}
                    onClick={() => handleSort("company")}
                  >
                    Group Name
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "industry" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "industry" ? sort.order : null}
                    onClick={() => handleSort("industry")}
                  >
                    Industry
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background:
                        sort.column === "reference" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "reference" ? sort.order : null}
                    onClick={() => handleSort("reference")}
                  >
                    Reference
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background:
                        sort.column === "groupSize" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "groupSize" ? sort.order : null}
                    onClick={() => handleSort("groupSize")}
                  >
                    Group Size
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background:
                        sort.column === "auditorium" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "auditorium" ? sort.order : null}
                    onClick={() => handleSort("auditorium")}
                  >
                    Auditorium
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "seats" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "seats" ? sort.order : null}
                    onClick={() => handleSort("seats")}
                  >
                    Seats
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background:
                        sort.column === "ticketPrice" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "ticketPrice" ? sort.order : null}
                    onClick={() => handleSort("ticketPrice")}
                  >
                    Ticket Price
                  </Table.HeaderCell>

                  {(isAdmin || isExhibitor) && (
                    <Table.HeaderCell
                      style={{
                        position: "sticky",
                        top: 0,
                        borderTop: "1px solid rgba(34,36,38,.15)",
                        background: sort.column === "studio" ? "aliceblue" : "",
                      }}
                      sorted={sort.column === "studio" ? sort.order : null}
                      onClick={() => handleSort("studio")}
                    >
                      Studio
                    </Table.HeaderCell>
                  )}

                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "value" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "value" ? sort.order : null}
                    onClick={() => handleSort("value")}
                  >
                    Box Office Gross
                  </Table.HeaderCell>

                  {(isAdmin || isStudio) && (
                    <Table.HeaderCell
                      style={{
                        position: "sticky",
                        top: 0,
                        borderTop: "1px solid rgba(34,36,38,.15)",
                        background:
                          sort.column === "missingOwner" ? "aliceblue" : "",
                      }}
                      sorted={
                        sort.column === "missingOwner" ? sort.order : null
                      }
                      onClick={() => handleSort("missingOwner")}
                    >
                      Has Active Exhibitor
                    </Table.HeaderCell>
                  )}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <CustomerList />
              </Table.Body>
            </Table>
          )}
          {(!loading && !customersList.length) ||
            (searched && !filteredList.length && (
              <Segment
                placeholder
                style={{ alignItems: "center", backgroundColor: "#F1F1F1" }}
              >
                <Header
                  content={
                    searched
                      ? "No Leads Match the Current Filter"
                      : "No Leads to Display"
                  }
                />
                <Icon size="small" name="info" circular />
              </Segment>
            ))}
        </div>
        <PageModal
          open={modalOpen}
          setOpen={setModalOpen}
          clearError={clearError}
          heading={mode === "edit" ? "Edit Lead / Event" : "Add Lead / Event"}
          size={mode === "edit" ? "fullscreen" : "small"}
          styles={{ padding: "50px" }}
          actions={
            <div>
              {!readOnly && (
                <Button
                  loading={loading}
                  disabled={disabled || readOnly}
                  size="large"
                  primary
                  content={mode === "add" ? "Add" : "Update"}
                  onClick={
                    mode === "edit" ? handleUpdateCustomer : handleSaveNewLead
                  }
                />
              )}
            </div>
          }
        >
          <AddEditCustomer
            errorFields={errorFields}
            mode={mode}
            statuses={statuses}
            exhibitorStatuses={exhibitorStatuses}
            isAdmin={isAdmin}
            isStudio={isStudio}
            isExhibitor={isExhibitor}
            handleChangeFieldString={handleChangeFieldString}
            customer={selectedCustomer}
            theaterList={theaterList}
            studioList={studioList}
            titleList={titleList}
            theater={getTheater(selectedCustomer.theaterId)}
            priorities={priorities}
            searchBy={searchBy}
            searchByOptions={searchByOptions}
            handleChangeSearchBy={handleChangeSearchBy}
            searchValue={searchValue}
            handleTheaterSearchValue={handleTheaterSearchValue}
            searchResults={searchResults}
            SearchResults={SearchResults}
            searchRadius={searchRadius}
            radiusOptions={radiusOptions}
            handleChangeRadius={handleChangeRadius}
            searching={searching}
            handleTheaterSearch={handleTheaterSearch}
            handleClearTheaterSelection={handleClearTheaterSelection}
            titleOptions={titleOptions}
          />
        </PageModal>
        <PageModal
          noScroll={true}
          open={isMessages}
          setOpen={setIsMessages}
          clearError={clearError}
          heading="Notes"
          size="small"
          actions={
            <div>
              <Button content="Close" onClick={() => setIsMessages(false)} />
            </div>
          }
        >
          <Messages
            messagesList={messagesList}
            handleAddMessage={handleAddMessage}
            newMessage={newMessage}
            handleChangeNewMessage={handleChangeNewMessage}
            handleDeleteMessage={handleDeleteMessage}
          />
        </PageModal>
        <PageModal
          noScroll={true}
          open={isPing}
          setOpen={setPing}
          clearError={clearError}
          heading="Ping Exhibitor"
          size="large"
          actions={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                size="large"
                content="Close"
                onClick={() => setPing(false)}
              />
              <Button
                loading={loading}
                disabled={loading}
                primary
                size="large"
                content="Send"
                icon="send"
                onClick={handlePingExhibitor}
              />
            </div>
          }
        >
          <Ping
            calculateBusinessDays={calculateBusinessDays}
            selectedCustomer={selectedCustomer}
            pingMessage={pingMessage}
            handleSetPingMessage={handleSetPingMessage}
            setPingMessage={setPingMessage}
          />
        </PageModal>
        {importing && (
          <Modal open>
            <Modal.Content>
              <Progress
                label={importMessage}
                active={!importSuccess}
                success={importSuccess}
                percent={importSuccess ? 100 : 60}
              />
            </Modal.Content>
          </Modal>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <Button
              size="large"
              style={{ marginTop: "20px" }}
              disabled={page === 1}
              icon="left arrow"
              onClick={() => handleChangePage("down")}
            />
            <Dropdown
              style={{ marginRight: "5px" }}
              selection
              compact
              scrolling
              options={getPages()}
              onChange={handleSelectPage}
              value={page || 0}
            />
            <Button
              size="large"
              style={{ marginTop: "20px" }}
              disabled={page === numPages}
              icon="right arrow"
              onClick={() => handleChangePage("up")}
            />
            <span>
              {page === 1 ? 1 : ((page - 1) * 50 + 1).toLocaleString()} to{" "}
              {page * 50 + 1 > recordsDisplayed
                ? recordsDisplayed.toLocaleString()
                : (page * 50 + 1).toLocaleString()}{" "}
            </span>
            <span>
              <b>({recordsDisplayed.toLocaleString()} total)</b>
            </span>
          </div>
          <div>
            {!readOnly && (
              <Popup
                open={isConfirmDelete}
                trigger={
                  <Button
                    negative
                    disabled={getUpdateDisabled()}
                    icon="ban"
                    size="large"
                    style={{ marginTop: "20px" }}
                    content="Delete"
                    onClick={handleConfirmDelete}
                  />
                }
                content={
                  <div>
                    <p>
                      Are you sure you want to Delete {getNumSelected()}{" "}
                      selected leads? This cannot be undone.
                    </p>

                    <Button
                      positive
                      content="Yes"
                      onClick={handleDeleteCustomer}
                    />
                    <Button
                      negative
                      content="No"
                      onClick={() => setConfirmDelete(false)}
                    />
                  </div>
                }
              />
            )}
            {!readOnly && (
              <Dropdown
                scrolling
                disabled={getUpdateDisabled()}
                icon={null}
                trigger={
                  <Button
                    primary
                    icon="caret down"
                    labelPosition="right"
                    size="large"
                    style={{ marginTop: "20px" }}
                    content="Status"
                  />
                }
              >
                <Dropdown.Menu>
                  <Dropdown.Header>Select a Status to Update</Dropdown.Header>
                  <Dropdown.Item
                    text="Booked"
                    onClick={() => handleBulkUpdate("Booked")}
                  />
                  <Dropdown.Item
                    text="Cancelled"
                    onClick={() => handleBulkUpdate("Cancelled")}
                  />
                  <Dropdown.Item
                    text="Cold"
                    onClick={() => handleBulkUpdate("Cold")}
                  />

                  <Dropdown.Item
                    text="Contacted"
                    onClick={() => handleBulkUpdate("Contacted")}
                  />
                  <Dropdown.Item
                    text="Duplicate"
                    onClick={() => handleBulkUpdate("Duplicate")}
                  />
                  {!isExhibitor && (
                    <Dropdown.Item
                      text="Event Approved by Studio"
                      onClick={() => handleBulkUpdate("Completed")}
                    />
                  )}
                  <Dropdown.Item
                    text="Flagged"
                    onClick={() => handleBulkUpdate("Flagged")}
                  />
                  {/* <Dropdown.Item
                    text="In Progress"
                    onClick={() => handleBulkUpdate("In Progress")}
                  /> */}
                  <Dropdown.Item
                    text="Insufficient Contact Details"
                    onClick={() =>
                      handleBulkUpdate("Insufficient Contact Details")
                    }
                  />
                  {!isExhibitor && (
                    <Dropdown.Item
                      text="New"
                      onClick={() => handleBulkUpdate("New")}
                    />
                  )}
                  <Dropdown.Item
                    text="Pending Studio Approval"
                    onClick={() => handleBulkUpdate("Pending Studio Approval")}
                  />
                  {!isExhibitor && (
                    <Dropdown.Item
                      text="Quote Approved, Ready For Payment"
                      onClick={() =>
                        handleBulkUpdate("Quote Approved, Ready For Payment")
                      }
                    />
                  )}
                  {!isExhibitor && (
                    <Dropdown.Item
                      text="Request For Quote"
                      onClick={() => handleBulkUpdate("Request For Quote")}
                    />
                  )}
                  {!isExhibitor && (
                    <Dropdown.Item
                      text="Send to Exhibitor"
                      onClick={() => handleBulkUpdate("Approved")}
                    />
                  )}
                </Dropdown.Menu>
              </Dropdown>
            )}

            {(isAdmin || isStudio) && (
              <Button
                disabled={getPingDisabled()}
                icon="send"
                size="large"
                style={{ marginTop: "20px" }}
                primary
                content="Ping"
                onClick={() => setPing(true)}
              />
            )}

            <Button
              icon="conversation"
              size="large"
              style={{ marginTop: "20px" }}
              primary
              disabled={getEditDisabled()}
              content="Notes"
              onClick={handleViewMessages}
            />

            <Button
              icon="edit"
              size="large"
              style={{ marginTop: "20px" }}
              primary
              disabled={getEditDisabled()}
              content="Details"
              onClick={handleEditCustomer}
            />

            {!readOnly && (
              <Dropdown
                icon={null}
                trigger={
                  <Button
                    size="large"
                    icon="plus"
                    content="Add"
                    positive
                    style={{ marginTop: "20px" }}
                  />
                }
              >
                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleAddNewLead}>
                    Add single lead
                  </Dropdown.Item>

                  <Dropdown.Item onClick={() => setPreImport(true)}>
                    Import from spreadsheet
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
            {!disableExport && (
              <Dropdown
                icon={null}
                trigger={
                  <Button
                    secondary
                    size="large"
                    icon="download"
                    content="Export"
                  />
                }
              >
                <Dropdown.Menu>
                  <Dropdown.Header>Select File Type</Dropdown.Header>
                  <Dropdown.Item onClick={() => handleExport("csv")}>
                    CSV
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleExport("xlsx")}>
                    XLSX
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </div>
      </div>
      <PageModal
        noScroll={false}
        open={isBulkUpdate}
        setOpen={setBulkUpdate}
        clearError={clearError}
        heading="Confirm Bulk Update"
        size="small"
        actions={
          <div>
            <Button
              disabled={bulkUpdating || success}
              loading={bulkUpdating}
              size="large"
              content="Cancel"
              onClick={handleCancelBulkUpdate}
            />
            <Button
              disabled={bulkUpdating || success}
              loading={bulkUpdating}
              size="large"
              content="Confirm"
              primary
              onClick={handleConfirmBulkUpdate}
            />
          </div>
        }
      >
        <Header
          content={`Are you sure you wish to update the following ${selectedCustomers.length} selected leads?`}
        />
        <Table>
          <Table.Header>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            {isBooking && <Table.HeaderCell>Box Office</Table.HeaderCell>}
            {isBooking && <Table.HeaderCell>Auditorium</Table.HeaderCell>}
          </Table.Header>
          <SelectedCustomers />
        </Table>
      </PageModal>
    </div>
  );
};
export default Customers;
