import { useEffect, useState, useRef, useContext } from "react";
import { Header, Table, Segment, Icon, Input, Button } from "semantic-ui-react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { TITLES, ADD_TITLE, UPDATE_TITLE, STUDIOS } from "../graphQueries";
import AddEditTitle from "./AddEditTitle";
import PageModal from "../PageModal";
import Alert from "../Alert/Alert";
import { AuthContext } from "../AuthContext";
import moment from "moment";
import { read } from "xlsx";

const Titles = (props) => {
  const { loginContext } = useContext(AuthContext);
  const [initializing, setInit] = useState(true);
  const [sort, setSort] = useState({ column: "", order: "ascending" });
  const [filteredList, setFiltered] = useState([]);
  const [titlesList, setTitlesList] = useState([]);
  const [searching, setSearching] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [studioList, setStudiosList] = useState([]);
  const isAdmin = loginContext.role.includes("Admin");

  const clearTimerRef = useRef();

  const readOnly = loginContext.readOnly;

  useEffect(() => {
    console.log(titlesList);
  }, [titlesList]);

  useEffect(() => {
    console.log(filteredList);
  }, [filteredList]);

  useEffect(() => {
    titles();
    studios();
  }, []);

  useEffect(() => {
    console.log(selectedTitle);
  }, [selectedTitle]);

  // titles query
  const [titles] = useLazyQuery(TITLES, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleTitles_Result(result),
    onError: (error) => handleTitles_Error(error),
  });
  const handleTitles_Result = (result) => {
    if (result.titles && result.titles.length) {
      const titleList = result.titles;
      setTitlesList(titleList);
      setInit(false);
    }
  };
  const handleTitles_Error = (error) => {
    console.log(error);
  };

  // studios query
  const [studios] = useLazyQuery(STUDIOS, {
    fetchPolicy: "network-only",
    onCompleted: (result) => handleStudios_Result(result),
    onError: (error) => handleStudios_Error(error),
  });
  const handleStudios_Result = (result) => {
    if (result.studios && result.studios.length) {
      let newArray = result.studios.map((item) =>
        Object.assign({}, item, { text: item.name, value: item.studioId })
      );

      setStudiosList(newArray);
    }
  };
  const handleStudios_Error = (error) => {
    console.log(error);
  };

  const handleSort = (column) => {
    const newOrder = sort.order === "ascending" ? "descending" : "ascending";
    let sortList = [...titlesList];
    if (filteredList && filteredList.length > 0) {
      sortList = [...filteredList];
    }

    if (column === "name" && newOrder === "ascending") {
      sortList.sort((a, b) => (a.name || "").localeCompare(b.name || ""));
    }
    if (column === "name" && newOrder === "descending") {
      sortList.sort((a, b) => (b.name || "").localeCompare(a.name || ""));
    }

    if (column === "rating" && newOrder === "ascending") {
      sortList.sort((a, b) => (a.rating || "").localeCompare(b.rating || ""));
    }
    if (column === "rating" && newOrder === "descending") {
      sortList.sort((a, b) => (b.rating || "").localeCompare(a.rating || ""));
    }

    if (column === "studioId" && newOrder === "ascending") {
      sortList.sort((a, b) =>
        (a.studioId || "").localeCompare(b.studioId || "")
      );
    }
    if (column === "studioId" && newOrder === "descending") {
      sortList.sort((a, b) =>
        (b.studioId || "").localeCompare(a.studioId || "")
      );
    }

    if (column === "releaseDate" && newOrder === "ascending") {
      sortList.sort((a, b) =>
        (a.releaseDate || "").localeCompare(b.releaseDate || "")
      );
    }
    if (column === "releaseDate" && newOrder === "descending") {
      sortList.sort((a, b) =>
        (b.releaseDate || "").localeCompare(a.releaseDate || "")
      );
    }

    if (column === "startDate" && newOrder === "ascending") {
      sortList.sort((a, b) =>
        (a.startDate || "").localeCompare(b.startDate || "")
      );
    }
    if (column === "startDate" && newOrder === "descending") {
      sortList.sort((a, b) =>
        (b.startDate || "").localeCompare(a.startDate || "")
      );
    }

    if (column === "endDate" && newOrder === "ascending") {
      sortList.sort((a, b) => (a.endDate || "").localeCompare(b.endDate || ""));
    }
    if (column === "endDate" && newOrder === "descending") {
      sortList.sort((a, b) => (b.endDate || "").localeCompare(a.endDate || ""));
    }

    if (column === "createdDate" && newOrder === "ascending") {
      sortList.sort((a, b) =>
        (a.createdDate || "").localeCompare(b.createdDate || "")
      );
    }
    if (column === "createdDate" && newOrder === "descending") {
      sortList.sort((a, b) =>
        (b.createdDate || "").localeCompare(a.createdDate || "")
      );
    }

    if (filteredList && filteredList.length > 0) {
      setFiltered(sortList);
    } else {
      setTitlesList(sortList);
    }
    setSort({ column, order: newOrder });
  };

  const handleFilter = (e) => {
    setSearching(true);
    const searchTerm = e.target.value.toUpperCase();
    const filteredArray = titlesList.filter((x) =>
      `${x.name}`.toUpperCase().includes(searchTerm)
    );
    clearTimeout(clearTimerRef.current);
    clearTimerRef.current = setTimeout(() => {
      setSearching(false);
      if (searchTerm === "") {
        setFiltered([]);
      } else {
        setFiltered(filteredArray);
      }
    }, 1000);
  };

  const handleAddTitle = () => {
    setModalOpen(true);
    setEditMode(false);
    setSelectedTitle({
      amcThreshold: "30",
      regalThreshold: "20",
      cinemarkThreshold: "20",
      marqueeThreshold: "50",
      defaultThreshold: "20",
    });
  };

  const handleEditTitle = () => {
    setModalOpen(true);
    setEditMode(true);
  };

  const clearError = () => {
    setErrorMessage("");
  };

  const handleChangeSelectedTitle = (e, data) => {
    // new handling here for all field changes
    console.log(e, data);
    const key = data.id;
    const value = data.value;
    const obj = { ...selectedTitle };
    if (data === "releaseDate" || data === "startDate" || data === "endDate") {
      Object.assign(obj, { [data]: e });
    }
    if (
      key === "name" ||
      key === "rating" ||
      key === "studioId" ||
      key === "amcText" ||
      key === "regalText" ||
      key === "cinemarkText" ||
      key === "amcThreshold" ||
      key === "regalThreshold" ||
      key === "cinemarkThreshold" ||
      key === "defaultThreshold" ||
      key === "marqueeText" ||
      key === "marqueeThreshold"
    ) {
      Object.assign(obj, { [key]: value });
    }
    setSelectedTitle(obj);
  };

  const handleSaveNewTitle = () => {
    if (!selectedTitle.name) return;
    setLoading(true);
    setDisabled(true);
    setTimeout(() => {
      if (editMode) {
        console.log("updateTitle() here");
      } else {
        addTitle({
          variables: {
            name: selectedTitle.name,
            rating: selectedTitle.rating,
            releaseDate: selectedTitle.releaseDate,
            startDate: moment(selectedTitle.startDate).format(
              "YYYY-MM-DDTHH:mm:ss"
            ),
            endDate: moment(selectedTitle.endDate).format(
              "YYYY-MM-DDTHH:mm:ss"
            ),
            studioId: loginContext.studioId || selectedTitle.studioId,
            amcText: selectedTitle.amcText,
            marqueeText: selectedTitle.marqueeText,
            marqueeThreshold: selectedTitle.marqueeThreshold,
            regalText: selectedTitle.regalText,
            cinemarkText: selectedTitle.cinemarkText,
            amcThreshold: selectedTitle.amcThreshold,
            regalThreshold: selectedTitle.regalThreshold,
            cinemarkThreshold: selectedTitle.cinemarkThreshold,
            defaultThreshold: selectedTitle.defaultThreshold,
          },
        });
      }
    }, 500);
  };

  const [addTitle] = useMutation(ADD_TITLE, {
    onCompleted: (result) => handleAddTitle_Result(result),
    onError: (error) => handleAddTitle_Error(error),
  });

  const handleAddTitle_Result = (result) => {
    console.log(result);
    if (result.addTitle && result.addTitle.status === "succeeded") {
      setLoading(false);
      setSuccess(true);
      setSuccessMessage("Title added successfully.");
      titles();
      resetStateTimer();
    } else {
      setErrorMessage("Failed to add title.");
      resetStateTimer();
    }
  };

  const handleAddTitle_Error = (error) => {
    console.log(error);
    setLoading(false);
    setDisabled(false);
    setErrorMessage(error.message);
    resetStateTimer();
  };

  const resetStateTimer = () => {
    setTimeout(() => {
      setLoading(false);
      setDisabled(false);
      setErrorMessage("");
      setSuccess(false);
      setSuccessMessage("");
      setModalOpen(false);
      setEditMode(false);
      setSelectedTitle({});
    }, 2000);
  };

  const handleUpdateTitle = () => {
    if (!selectedTitle) return;
    setLoading(true);
    setDisabled(true);
    setTimeout(() => {
      updateTitle({
        variables: {
          titleId: selectedTitle.titleId,
          name: selectedTitle.name,
          rating: selectedTitle.rating,
          releaseDate: selectedTitle.releaseDate,
          startDate: moment(selectedTitle.startDate).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          endDate: moment(selectedTitle.endDate).format("YYYY-MM-DDTHH:mm:ss"),
          amcText: selectedTitle.amcText,
          marqueeText: selectedTitle.marqueeText,
          marqueeThreshold: selectedTitle.marqueeThreshold,
          regalText: selectedTitle.regalText,
          cinemarkText: selectedTitle.cinemarkText,
          amcThreshold: selectedTitle.amcThreshold,
          regalThreshold: selectedTitle.regalThreshold,
          cinemarkThreshold: selectedTitle.cinemarkThreshold,
          defaultThreshold: selectedTitle.defaultThreshold,
        },
      });
    }, 500);
  };

  const [updateTitle] = useMutation(UPDATE_TITLE, {
    onCompleted: (result) => handleUpdateTitle_Result(result),
    onError: (error) => handleUpdateTitle_Error(error),
  });

  const handleUpdateTitle_Result = (result) => {
    console.log(result);
    if (result.updateTitle && result.updateTitle.status === "succeeded") {
      console.log("update success");
      setLoading(false);
      setSuccess(true);
      setSuccessMessage("Title updated successfully.");
      titles();
      resetStateTimer();
    } else {
      setLoading(false);
      setErrorMessage("Failed to update.");
      resetStateTimer();
    }
  };

  const handleUpdateTitle_Error = (error) => {
    console.log(error);
    setErrorMessage(error.message);
    setLoading(false);
    setDisabled(false);
  };

  // render -->

  const getStudio = (studioId) => {
    const studio = studioList.filter((x) => x.value === studioId);
    console.log(studio);
    if (studio.length) {
      return studio[0].text;
    }
  };

  const TitlesList = () => {
    let titles = titlesList;
    if (filteredList && filteredList.length > 0) {
      titles = filteredList;
    }
    return titles.map((item, index) => {
      return (
        <Table.Row
          warning={item.archived}
          active={item.titleId === selectedTitle.titleId}
          key={index}
          onClick={() => setSelectedTitle(item)}
        >
          <Table.Cell>{item.titleId}</Table.Cell>
          <Table.Cell>{moment(item.createdDate).format("LL")}</Table.Cell>
          <Table.Cell>{item.name}</Table.Cell>
          <Table.Cell>{item.rating}</Table.Cell>
          {isAdmin && <Table.Cell>{getStudio(item.studioId)}</Table.Cell>}
          <Table.Cell>{moment(item.releaseDate).format("LL")}</Table.Cell>
          <Table.Cell>{moment(item.startDate).format("LL")}</Table.Cell>
          <Table.Cell>
            {!item.endDate ? "-" : moment(item.endDate).format("LL")}
          </Table.Cell>
        </Table.Row>
      );
    });
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 50px)",
        width: "100vw",
        position: "absolute",
        top: "50px",
      }}
    >
      {success && <Alert successMessage={successMessage} />}
      <div
        style={{
          maxWidth: "95%",
          maxHeight: "90%",
          backgroundColor: "#F1F1F1",
          borderRadius: "10px",
          width: "100%",
          padding: "20px",
          zIndex: "1",
          boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.25)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header style={{ textAlign: "center" }} content="Titles" />
        <Input
          style={{ marginBottom: "20px" }}
          placeholder="Search..."
          icon={
            searching ? (
              <Icon loading name="circle notch" />
            ) : (
              <Icon name="search" />
            )
          }
          iconPosition="left"
          onChange={handleFilter}
        />
        <div style={{ overflowY: "auto", maxHeight: "70vh" }}>
          {initializing && (
            <Segment
              placeholder
              style={{
                alignItems: "center",
                backgroundColor: "#F1F1F1",
              }}
            >
              <Header content="Loading Titles..." />
              <Icon size="big" loading name="circle notch" />
            </Segment>
          )}
          {!initializing && (
            <Table
              sortable
              selectable
              style={{
                borderTop: "0px",
                whiteSpace: "nowrap",
              }}
            >
              <Table.Header
                style={{
                  border: "0px",
                  backgroundColor: "#F1F1F1",
                }}
              >
                <Table.Row>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "titleId" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "titleId" ? sort.order : null}
                    onClick={() => handleSort("titleId")}
                  >
                    ID
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background:
                        sort.column === "createdDate" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "createdDate" ? sort.order : null}
                    onClick={() => handleSort("createdDate")}
                  >
                    Created
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "name" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "name" ? sort.order : null}
                    onClick={() => handleSort("name")}
                  >
                    Name
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "rating" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "rating" ? sort.order : null}
                    onClick={() => handleSort("rating")}
                  >
                    Rating
                  </Table.HeaderCell>
                  {isAdmin && (
                    <Table.HeaderCell
                      style={{
                        position: "sticky",
                        top: 0,
                        borderTop: "1px solid rgba(34,36,38,.15)",
                        background:
                          sort.column === "studioId" ? "aliceblue" : "",
                      }}
                      sorted={sort.column === "studioId" ? sort.order : null}
                      onClick={() => handleSort("studioId")}
                    >
                      Studio
                    </Table.HeaderCell>
                  )}

                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background:
                        sort.column === "releaseDate" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "releaseDate" ? sort.order : null}
                    onClick={() => handleSort("releaseDate")}
                  >
                    Release Date
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background:
                        sort.column === "startDate" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "startDate" ? sort.order : null}
                    onClick={() => handleSort("startDate")}
                  >
                    Start Date
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      position: "sticky",
                      top: 0,
                      borderTop: "1px solid rgba(34,36,38,.15)",
                      background: sort.column === "endDate" ? "aliceblue" : "",
                    }}
                    sorted={sort.column === "endDate" ? sort.order : null}
                    onClick={() => handleSort("endDate")}
                  >
                    End Date
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <TitlesList />
              </Table.Body>
            </Table>
          )}
        </div>
        <PageModal
          open={modalOpen}
          setOpen={setModalOpen}
          clearError={clearError}
          heading={editMode ? "Edit Title" : "Add Title"}
          actions={
            <div>
              {!readOnly && (
                <Button
                  size="large"
                  positive
                  icon="check"
                  loading={loading}
                  disabled={disabled}
                  content="Save"
                  onClick={editMode ? handleUpdateTitle : handleSaveNewTitle}
                />
              )}
            </div>
          }
        >
          <AddEditTitle
            studioList={studioList}
            errorMessage={errorMessage}
            selectedTitle={selectedTitle}
            handleChangeSelectedTitle={handleChangeSelectedTitle}
          />
        </PageModal>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {!readOnly && (
            <Button
              icon="edit"
              size="large"
              disabled={Object.keys(selectedTitle).length === 0}
              style={{ marginTop: "20px" }}
              primary
              content="Edit"
              onClick={handleEditTitle}
            />
          )}
          {!readOnly && (
            <Button
              icon="plus"
              size="large"
              style={{ marginTop: "20px" }}
              positive
              content="Add"
              onClick={handleAddTitle}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default Titles;
