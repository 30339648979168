import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Dashboard from "./Dashboard";
import Intake from "./Intake/Intake";
import SignUp from "./SignUp/SignUp";
import ComingSoon from "./ComingSoon/ComingSoon";
import ThankYou from "./Intake/ThankYou";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/intake" element={<Intake />} />
        <Route path="/reagan" element={<Intake />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/comingsoon" element={<ComingSoon />} />
        <Route path="/thankyou" element={<ThankYou />} />
      </Routes>
    </Router>
  );
};

export default App;
