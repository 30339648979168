import { useEffect, useState } from "react";
import { Header } from "semantic-ui-react";
import AutoLinkText from "react-autolink-text2";

const ThankYou = () => {
  const [message, setMessage] = useState("");
  useEffect(() => {
    const queryString = window.location.search;
    console.log(queryString);
    const urlParams = new URLSearchParams(queryString);
    const message = urlParams.get("message");
    setMessage(message);
    const newUrl = window.location.origin + window.location.pathname;
    window.history.replaceState({}, document.title, newUrl);
  }, []);
  return (
    <div
      style={{
        backgroundColor: "#000000",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <Header inverted>
        <AutoLinkText text={message} />
      </Header>
    </div>
  );
};

export default ThankYou;
